/* Slider: thumbs */
.Slider-thumbnails {
    margin-top: u(18);
}

.Slider-thumbnails-overlay {
    background: rgba(16, 167, 75, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity $global-transition;

    .Icon {
        width: u($spacing-unit);
        height: u($spacing-unit);
        color: $white;
    }
}

.Slider-thumbnails-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &.is-active {
        .Slider-thumbnails-overlay {
            opacity: 1;
        }
    }
}



