/* Main */
html {
    background-color: $bg-color;
    color: $text-color;
    font-family: $font-sans;
    height: 100%;
    scroll-behavior: smooth;

    &.fonts-loaded {
        font-family: $font-body;
        line-height: 1.375;
    }

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    height: 100%;
    font-size: u(14);

    @include mq($from: $viewport--lg) {
        font-size: u(16);
    }
}

.Main {
    padding-top: var(--header-height);
}

.Main-content {
    padding: u($spacing-unit--lg) 0 u($spacing-unit--xxl);
    margin-left: auto;
    margin-right: auto;

    @include mq($until: $viewport--lg) {
        padding-top: u($spacing-unit--sm);
    }
    &.Main-content--lg {
        max-width: u($container-large);
    }
    &.Main-content--md {
        max-width: u($container-medium);
    }
    &.Main-content--sm {
        max-width: u($container-blog);
    }
}
.ScrollTo[name] {
    position: relative;
    top: clamp(u(-140), 15vw, u(-220));
    display: block;
}
.DealersOverview {
    display: grid;
    gap: 4rem;

    @include mq($from: 1024px) {
        grid-template-columns: 0.875fr 1.125fr;
    }
}

.DealersOverview-side > .DealersOverview-inner {
    position: sticky;
    top: 20rem;
}

.CountrySwitcherPage .Main {
    padding-top: 0;
}

.CountrySwitcherPage .Main-content {
    padding: 4rem 0;
}

.LanguageSwitcher {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.LanguageSwitcher-top {
    position: relative;
}

.LanguageSwitcher-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.LanguageSwitcher-object {
    max-height: 70vh;
}

.LanguageSwitcher-image {
    object-fit: cover;
    width: 100%;
}

.LanguageSwitcher-bottom {
    position: relative;
    z-index: 1;

    @include mq($until: 919px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
        background-color: $primary-color-lighter;
    }
}

.LanguageSwitcher-navWrapper {

    @include mq($from: 920px) {
        margin-top: -10rem;
    }
}

.LanguageSwitcher-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: -2rem;
    margin-bottom: 0;
    margin-left: -2rem;
    padding: 0;
    list-style: none;

    @include mq($until: 919px) {
        flex-direction: column;
    }
}

.LanguageSwitcher-navItem {
    margin: 0;
    padding-top: 2rem;
    padding-left: 2rem;

    @include mq($until: 919px) {
        width: 100%;
        max-width: 64rem;

        > .Button {
            width: 100%;
        }
    }
}

.CountrySwitcher-nav {

    @include mq($until: 767px) {
        flex-direction: column;
        margin-top: 2rem !important;

        .Hero-list-item {
            margin-top: 0.125rem;
        }
    }
}

.ErrorBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    padding-top: 4rem;
    padding-bottom: 2rem;
    max-width: 82rem;

    @include mq($from: 768px, $until: 1023px) {
        padding-top: 6rem;
    }
}

.ErrorBlock-title {
    color: #0c6667;
    font-size: 6rem;
}

.ErrorBlock-text {
    margin-right: auto;
    margin-left: auto;
    max-width: 64rem;
    color: #0c6667;
    text-align: center;
}

.ErrorBlock-media {
    margin-bottom: 2rem;
}

.ErrorBlock-buttons {
    display: flex;
    margin: 0;
    margin-top: 2rem;
    padding: 0;
    list-style: none;
}

.ErrorBlock-buttonsItem {
    margin-right: 1rem;
    margin-left: 1rem;
}

.ErrorBlock-buttonsLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 12rem;
    height: 100%;
    border: 2px solid #058E77;
    color: #058E77;
    text-decoration: none;
    text-align: center;
    background-color: white;
    transition: color 150ms background-color 150ms border-color 150ms;
}

.ErrorBlock-buttonsLink:hover {
    border-color: #058E77;
    color: #058E77;
    background-color: white;
}

.ErrorBlock-buttonsImage {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 5rem;
}
