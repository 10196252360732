.Author {
    border-top: 1px solid $primary-color-light;
    border-bottom: 1px solid $primary-color-light;
    padding: u(9) 0 u($spacing-unit--sm);
    position: relative;
    margin-bottom: u($spacing-unit--lg);

    @include mq($from: $viewport--md) {
        display: flex;
        justify-content: space-between;
    }

    em {
        font-size: u(13);

        @include mq($from: $viewport--md) {
           font-size: u(14);
        }
    }
}

.Author-image {
    position: absolute;
    width: u(84 + $spacing-unit--sm + $spacing-unit--sm);
    height: u(84 + $spacing-unit--sm + $spacing-unit--sm);
    border: u($spacing-unit--sm) solid $white;
    border-radius: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    overflow: hidden;
}
