/* Icon */
.Icon {
    display: block;
    overflow: visible;

    .Button &,
    .Link & {
        flex-shrink: 0;
    }
}

.Icon--dealer,
.Icon--blog,
.Icon--print {
    width: u(24);
    height: u(24);
}

.Icon--lg {
    width: 3.5rem !important;
    height: 3.5rem !important;
}
