.Preview {
    background: $primary-color-lighter;
    border-radius: u(3);
    overflow: hidden;
    margin-bottom: u($spacing-unit--lg);
    display: block;
    width: 100%;
}

.Preview-link {
    display: block;

    &:hover {
        text-decoration: none;
    }

    // @include mq($until: $viewport--sm) {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: stretch;

    //     .Preview-content {
    //         flex: 1;
    //     }
    // }
}

// @include mq($until: $viewport--sm) {
//     .Preview-figure-wrapper,
//         .Preview-figure-object {
//             order: 2;
//             flex-basis: u(100);
//             min-width: 100%;
//         }

//         .Preview-figure-wrapper {
//             padding-bottom: 56%;
//         overflow: hidden;
//     }

//     .Preview-figure-object {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//     }
// }

.Preview-content {
    padding: u(18);
    display: block;

    h4 {
        font-family: $font-body;
        font-size: u(16);
        font-weight: 700;
        color: $text-color;
    }

    &:hover {
        text-decoration: none;
    }
}

.Preview-excerpt {
    color: $text-color;
}

.Preview-description {
    display: none;
}

.Preview-subtitle,
.Preview-description {
    color: $text-color;
}

.Preview-figure-wrapper {
    position: relative;
}

.Preview-tags {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: u(-2);
    left: u(18);
}

.Preview-tag-link {
    border-radius: rem(10) 0;
    padding: u(4) u($spacing-unit--sm) u($spacing-unit--xs);
    background: $primary-color;
    color: $white;
    font-size: u(14);
    position: relative;
    display: inline-block;
    transition: background $global-transition;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: u(7);
        height: u(10);
        bottom: u(-10);
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAtMjRoMjhWMEg3YTUgNSAwIDAgMC01IDV2NGExIDEgMCAwIDEtMiAwdi0yM2MwLTUuNTIzIDQuNDc3LTEwIDEwLTEweiIgZmlsbD0iIzEwQTc0QiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
    }

    &:hover {
        text-decoration: none;
        background-color: $primary-color-darker;
        color: $white;

        &:after {
            background-image: url(url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAtMjRoMjhWMEg3YTUgNSAwIDAgMC01IDV2NGExIDEgMCAwIDEtMiAwdi0yM2MwLTUuNTIzIDQuNDc3LTEwIDEwLTEweiIgZmlsbD0iIzBjNzgzNiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+));
        }
    }
}

.Preview-arrow {
    width: u($spacing-unit);
    transition: transform $global-transition;
}

.Preview-figure-image {
    width: 100%;
    transition: transform $global-transition;
}

/**
 * Modifiers
 */
.Preview--light {
    background: $white;
}

.Preview--tiny {
    .Preview-content {
        padding: u($spacing-unit--sm) u(14);
        color: $tertiary-color;
    }
}

.Preview--large {
    &:hover {
        .Preview-arrow {
            transform: translateX(50%);
        }

        .Preview-figure-image {
            transform: scale(1.1);
        }
    }
}

.Preview--product {
    .Preview-link {
        display: block;
    }
}

.Preview--list {
    background: none;

    .Preview-tags {
        display: none;
    }

    .Preview-description {
        display: block;
    }

    @include mq($from: 640px) {

        .Preview-link {
            display: flex;
        }

        .Preview-figure-wrapper {
            flex-basis: 25%;
        }

        .Preview-content {
            flex-basis: 75%;
        }
    }
}
