.Hero {
    position: relative;
    height: 70vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.Hero-image-object {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
}

.Hero-content {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: u($spacing-unit--lg);
}

.Hero-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.Hero-list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Hero-list-item {
    margin: u($spacing-unit--sm);
}
