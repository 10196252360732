.Notification {
    @include transition(margin-top);
    background-color: $orange;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: u($spacing-unit--lg);
    text-align: center;
    padding-top: u($spacing-unit--sm);
    padding-bottom: u($spacing-unit--sm);

    > .Container {
        @include grid(1fr auto 1fr, 1fr);
        grid-column-gap: u($spacing-unit--sm);
    }

    &-close {
        @include grid-area(3, 4, 1, 2);
        color: $white;
        cursor: pointer;
        font-weight: 700;
        justify-self: end;
    }

    &-body {
        @include grid-area(2, 3, 1, 2);

        :last-child {
            margin-bottom: 0;
        }

        a:not([class]) {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            &,
            &:active,
            &:focus,
            &:hover {
                color: currentColor;
            }
        }
    }
}
