/* Tab */
.tab-wrapper {
	$radius: 10px;
	background: #fff;
	border-radius: $radius;
	.tab-btns {
		display: flex;
		justify-content: space-between;
		align-items: center;
        .Button {
            border: 1px solid $primary-color;
            color: $primary-color;
            flex: 1;
            background-color: transparent;
            border-radius: 0;
            &:first-child {
                border-top-left-radius: 3px;
            }
            &:last-child {
                border-top-right-radius: 3px;
            }
            &.active {
                background-color: $primary-color;
                color: $white;
            }
        }
	}
	.tab-contents {
		padding: 30px;
        border: 1px solid $primary-color-lighter;
		h4 {
			font-size: 1.5rem;
			font-weight: 500;
			// text-transform: capitalize;
			margin-bottom: 10px;
		}
		p {
			font-size: 1.4rem;
			font-weight: 300;
		}
	}
}
/* show/hide content */
.tab-contents {
	.content {
		display: none;
	}
	.content.active {
		display: block;

		animation-name: fadeIn;
		animation-duration: 400ms;
		animation-timing-function: ease-in-out;
		animation-iteration-count: 1;
		&.finished {
			.intro {
				display: none;
			}
			.Form--content {
				border-top: none;
				padding-top: 0;
			}
			~ #formDownloadAsset {
				display: block;
			}
		}
	}
}
#formDownloadAsset {
    display: none;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(2px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
