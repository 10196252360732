.Nav--header-sub {
    margin-left: u(-$spacing-unit);
    margin-right: u(-$spacing-unit);

    .Nav-item {
        flex: 1 1 auto;
       position: relative;

       &:hover {
            .Nav-list-sub {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .Nav-link {
        padding: u($spacing-unit--sm) 1.6rem;
        color: $tertiary-color;
        text-align: center;

        &.is-active,
        &:hover,
        &:focus {
            color: $primary-color;
        }
    }

    .Nav-list-sub {
        position: absolute;
        top: 100%;
        left: 50%;
        background: $white;
        margin: 0;
        width: u(210);
        box-shadow: 0 u(10) u(15) 0 rgba($black, 0.1);
        list-style: none;
        padding: u($spacing-unit) 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity $global-transition;
        transform: translateX(-50%);
        z-index: 10;

        .Nav-link {
            padding: u($spacing-unit--xs) u($spacing-unit + $spacing-unit--sm);
        }
    }
}
