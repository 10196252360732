/* Slider: navigation */
.Slider-button {
    @include dimensions(36, 36);
    @include position(absolute, $top: 50%, $z: 10);
    background-position: center;
    background-repeat: no-repeat;
    color: $white;
    background: $primary-color;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: u(-22);

    &.is-disabled {
        display: none;
    }

    &:after {
        font-family: Slider-icons;
        font-size: u(14);
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
        font-weight: bold;
    }

    &.is-locked {
        display: none;
    }
}

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
    right: auto;
    left: 0;
    transform: translateX(-50%);

    &:after {
        content: 'prev';
    }
}

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
    left: auto;
    right: 0;
    transform: translateX(50%);

    &:after {
        content: 'next';
    }
}
