.Dealer {
    &--default,
    &--extended {
        border: 1px solid $cc-grey;
        border-radius: $button-border-radius;
        margin: u($spacing-unit--sm) 0;
    }

    &--small,
    &--tiny {
        border-bottom: 1px solid $cc-grey;
        padding: u($spacing-unit--sm) u($spacing-unit * 0.75);
    }

    &--tiny {
        color: $grey-darker;
    }

    &--extended {
        .Dealer-header {
            @include mq($until: $viewport--md) {
                display: block;
                padding-bottom: u(60);
            }
        }

        .Dealer-tags {
            @include mq($until: $viewport--md) {
                position: absolute;
                bottom: 0;
            }
        }
    }
}

.Dealer-title {
    &--small {
        color: $tertiary-color;
    }

    &--tiny {
        font-weight: 400;
        color: $primary-color;
        font-size: u(16);

        .fonts-loaded & {
            font-family: $font-body;
        }
    }
}

.Dealer-icon {
    width: u($spacing-unit);
    color: $primary-color;
    margin-left: auto;
    margin-right: 0;
}

.Dealer-address {
    font-family: $font-highlight;
    font-size: u(16);
    line-height: u(22);
    color: $tertiary-color;
    font-style: normal;

    @include mq($from: $viewport--md) {
        font-size: u(18);
        line-height: u(26);
    }
}

.Dealer-header {
    color: $tertiary-color;
    background: $primary-color-lighter;
    padding: u($spacing-unit * 0.75) u($spacing-unit * 0.75) 0 u($spacing-unit * 0.75);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.Dealer-footer {
    padding: u($spacing-unit * 0.75);

    &--small {
        font-size: u(14);
    }
}

.Dealer-tags {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.Dealer-details {
    color: $black;

    @include mq($until: $viewport--md) {
        font-size: u(14);
    }
}

.Dealer-tag {
    border-radius: rem(10) 0;
    padding: u(4) u($spacing-unit--sm) u($spacing-unit--xs);
    background: $primary-color;
    color: $white;
    font-size: u(14);
    position: relative;
    display: inline-block;
    transition: background $global-transition;
    margin-right: u($spacing-unit--xs);

    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: u(7);
        height: u(10);
        bottom: u(-10);
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAtMjRoMjhWMEg3YTUgNSAwIDAgMC01IDV2NGExIDEgMCAwIDEtMiAwdi0yM2MwLTUuNTIzIDQuNDc3LTEwIDEwLTEweiIgZmlsbD0iIzEwQTc0QiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
    }

    &:hover {
        text-decoration: none;
        background-color: $primary-color-darker;
        color: $white;

        &:after {
            background-image: url(url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAtMjRoMjhWMEg3YTUgNSAwIDAgMC01IDV2NGExIDEgMCAwIDEtMiAwdi0yM2MwLTUuNTIzIDQuNDc3LTEwIDEwLTEweiIgZmlsbD0iIzBjNzgzNiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+));
        }
    }
}
