.Search-form {
    position: relative;

    .Form-item--input {
        margin-bottom: 0;
    }
}

.Search-input {
    background: $primary-color-lighter;
    border-radius: u(40);
    padding: u(10) u($spacing-unit--lg);
    border: none;

    @include mq($from: $viewport--lg) {
        background: rgba($white, 0.4);
    }
}

.Search-button {
    border-radius: 50%;
    width: u(40);
    height: u(40);
    background: $primary-color;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;

    @include mq($from: $viewport--lg) {
        background: $white;
        color: $primary-color;
    }
}

.Search-icon {
    width: u(16);
    height: u(16);
}

::-webkit-input-placeholder {
    color: rgba($text-color, 0.85);

    @include mq($from: $viewport--lg) {
        color: $white;
    }
}

::-moz-placeholder {
    color: rgba($text-color, 0.85);

    @include mq($from: $viewport--lg) {
        color: $white;
    }
}
:-ms-input-placeholder {
    color: rgba($text-color, 0.85);

    @include mq($from: $viewport--lg) {
        color: $white;
    }
}

:-moz-placeholder {
    color: rgba($text-color, 0.85);

    @include mq($from: $viewport--lg) {
        color: $white;
    }
}
