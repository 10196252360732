/* Main */
.Nav--main {

    @include mq($until: $viewport--md - 1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
    }

    // List
    .Nav-list {
        // margin-right: u($spacing-unit);

        @include mq($until: $viewport--md - 1) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    // Item
    .Nav-item {
        padding-left: 1rem;

        @include mq($until: $viewport--md - 1) {

            + .Nav-item {
                margin-top: u($spacing-unit--sm);
            }

            .show-nav & {
                @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                @for $i from 1 through 10 {
                    $delay: (0.02s * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }
            }

            html:not(.show-nav) & {
                opacity: 0;
                transform: translateY(#{u($spacing-unit)});
            }
        }
    }

    // Link
    .Nav-link {

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        &.Button--white {
            color: $primary-color;

            &:hover {
                background: $primary-color-darker;
            }
        }
    }
}
