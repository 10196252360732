.Nav--footer-sub {

    .Nav-list {
        margin-top: u(-$spacing-unit--sm);
        margin-left: u($spacing-unit--xs);
        flex-wrap: wrap;

        @include mq($from: $viewport--md, $until: $viewport--lg) {
            max-width: u(400);
        }

        @include mq($until: $viewport--md) {
            display: block;
        }
    }

    .Nav-item {
        @include mq($from: $viewport--md, $until: $viewport--lg) {
            flex-basis: 50%;
        }

        @include mq($until: $viewport--md) {
            &:first-child {
                margin-left: 0;
            }

            margin-left: u(-48);
        }
    }

    .Nav-link {
        color: $white;
        padding: u($spacing-unit--sm) u($spacing-unit--lg / 2);

        &:hover,
        &:focus,
        &.is-active {
            text-decoration: underline;
        }
    }

    .Icon-chevron {
        display: none;
    }
}
