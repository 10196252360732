.Category-preview {
    background: $white;
    border-radius: u($button-border-radius);
    overflow: hidden;
    display: block;
    cursor: pointer;

    &:hover {
        .Category-preview-image {
            transform: scale(1.1);
        }
    }
}

.Category-preview-title {
    padding: u($spacing-unit--sm) u(18);


    h3 {
        font-size: u(14);
        color: $tertiary-color;
        font-weight: 400;
        margin: 0;

        .fonts-loaded & {
            font-family: $font-body;
        }

        @include mq($from: $viewport--md) {
            font-size: u(16);
        }
    }
}

.Category-preview-image {
    transition: transform $global-transition;
}
