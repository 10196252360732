.Product-details {
    background: $primary-color-lighter;
    padding: u($spacing-unit);
    margin-top: u($spacing-unit);
}

.Product-print {
    display: none;
}

.Product-usp {
    margin: u($spacing-unit) 0;
    font-style: italic;
    font-weight: 400;
    color: $grey-dark;
    font-size: 14px;

    .Icon-usp {
        width: u(30);
        height: u(30);
        margin: u($spacing-unit--sm) u($spacing-unit--sm) u($spacing-unit--sm) 0;
    }
}

.Product-details-item {
    border-bottom: 1px solid $primary-color-light;
    padding: u($spacing-unit--sm) 0;

    p {
        margin-bottom: 0;
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border: none;
        padding-bottom: 0;
    }
}

.Product-details-label {
    color: $tertiary-color;
}

.Product-price {
    font-weight: 700;
    font-size: u(19);

    @include mq($from: $viewport--md) {
        font-size: u(24);
    }
}

.Product-price-sub {
    font-weight: 700;
    font-size: u(16);

    @include mq($from: $viewport--md) {
        font-size: u(18);
    }
}

.Product-related {
    padding: u($spacing-unit--lg) 0;

    &--outline {
        border-top: 1px solid $primary-color-light;
    }

    + .Product-form {
        background-color: transparent;
    }
}

.Product-form {
    padding: u($spacing-unit--lg) 0;

    // Anchor point
    > a[id] {
        display: block;
        position: relative;
        top: calc((var(--header-height) + #{u($spacing-unit--lg)}) * -1);
    }
}

.Product-subtitle {
    color: $tertiary-color;
    font-size: 1.4rem;
    line-height: 2;
}

.Product-onlyCustom {
    position: relative;
    padding-left: 3.25rem;
    color: $orange-dark;
    font-size: 1.6rem;

    &::before {
        content: '';
        position: absolute;
        top: 0.125rem;
        left: 0;
        width: 2.2rem;
        height: 2.2rem;
        background-image: url(#{$path-img}icons/warning.png);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
    }
}
