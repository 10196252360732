/* Navigation */
.Navigation {

    &-toggle {
        display: block;
    }

    &-body {
        display: flex;
        margin-left: 0;

        @include mq($until: $viewport--md - 1) {
            @include transition(#{opacity, visibility});
            position: fixed;
            left: 0;
            top: 0;
            background-color: rgba($black, 0.7);
            flex-direction: column;
            justify-content: space-between;
            height: calc(var(--vh, 100vh));
            width: 100vw;

            html:not(.show-nav) & {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &-body-main {
        @include mq($until: $viewport--md) {
            background: $header-bg-color;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &-body-header {
        display: flex;
        align-items: flex-start;
        margin-bottom: u($spacing-unit * 2.5);
        color: $white;
        justify-content: space-between;

        @include mq($until: $viewport--md) {
            padding: u($spacing-unit * 0.75, 20, 0, 20);
        }

        .Navigation-toggle {
            position: absolute;
            top: u(26);
            right: u(20);
        }
    }

    &-body-footer {

        @include mq($until: $viewport--md) {
            padding: u(0, 20);
        }
    }

    &-back {
        display: flex;
        align-items: center;
        opacity: 0.7;
        color: $white;
        margin-top: 0;
        margin-right: 5rem;

        .Icon-chevron {
            width: u(5);
            transform: rotate(-180deg);
            margin: u($spacing-unit--sm);
        }
    }

    &-search {
        flex: 1;
        margin-left: u($spacing-unit--lg);
        max-width: u(455);

        @include mq($from: 1350px) {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

/* Types */
@import '6-components/navigation/main';
@import '6-components/navigation/lang';
@import '6-components/navigation/lang-extended';
@import '6-components/navigation/footer';
@import '6-components/navigation/footer-sub';
@import '6-components/navigation/header-sub';
@import '6-components/navigation/header-main';
