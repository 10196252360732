.Nav--lang-extended {

    &.is-open {
        .Nav-toggle {
            opacity: 0;
            visibility: hidden;
        }

        .Nav-list {
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
        }
    }

    .Nav-toggle {
        text-transform: none;
        display: flex;
        justify-content: space-between;
        font-size: rem(16);
        padding-bottom: u(80);
    }

    .Icon-chevron {
        width: u(5);
    }

    .Nav-list {
        top: 9rem;
        left: u(20);
        background: $primary-color;
        display: block;
        color: $white;
        font-size: u(20);
        width: 100%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        height: 60%;

        li:first-child {
            font-weight: 600;
            margin-bottom: u($spacing-unit);
        }
    }

    .Nav-link {
        padding: 0;
        color: $white;
        text-transform: capitalize;
        padding: u($spacing-unit * 0.75) 0;
        border-bottom: 1px solid rgba($white, 0.2);
        position: relative;

        span {
            display: block;
            transition: transform $global-transition;
        }

        &:hover,
        &:focus,
        &:active {
            color: rgba(white, 0.8);
        }

        &.is-active {
            font-weight: 600;
            color: $white;

            span {
                transform: translateX(24px);
            }

            &:before {
                opacity: 1;
            }
        }

        &:before {
            content: '';
            display: block;
            width: u(8);
            height: u(8);
            background: $white;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            opacity: 0;
            transition: opacity $global-transition;
        }
    }
}
