.Cta {
    background: $secondary-color-light;
    color: $secondary-color-dark;
    padding: u($spacing-unit);
    text-align: center;
    border-radius: $button-border-radius;

    h3 {
        color: $secondary-color-dark;
    }
}

.Cta-content {
    padding: u($spacing-unit--lg);
}

.Cta-arrow {
    width: u($spacing-unit);
    transition: transform $global-transition;
}

.Cta-figure-object {
    min-width: 100%;
    min-height: 100%;
}

.Cta-figure-image {
    width: 100%;
    max-width: none;
    height: auto;
    transition: transform $global-transition;
}

/**
 * Modifiers
 */
.Cta--primary {
    color: $white;
    background: linear-gradient(-179deg, #6CBC43 0%, $primary-color 100%);

    h3,
    h4 {
        color: $white;
    }

    h4 {
        font-size: u(16);

        @include mq($from: $viewport--md) {
            font-size: u(18);
        }
    }

    &:hover {
        .Cta-arrow {
            transform: translateX(50%);
        }

        .Cta-figure-image {
            transform: scale(1.1);
        }
    }

    .Icon {
        width: u($spacing-unit);
        height: u($spacing-unit);
    }

    &.Cta--small {
        padding: u($spacing-unit * 0.75);
        text-align: left;
        margin-top: u($spacing-unit--xs);

        p {
            margin: 0;
        }
    }
}

.Cta--large {
    text-align: left;

    ul {
        font-family: $font-highlight;
        font-size: u(18);
        list-style: none;
        padding: 0;

        li {
            padding: u($spacing-unit--xs) 0;
            padding-left: u(22);
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: u(13);
                left: 0;
                width: u(9);
                height: u(7);
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZGVmcz48cGF0aCBkPSJNMTcuODg1IDE1YzIuMjg0IDAgNC4xNDIgMS43MjQgNC4xNDIgMy44NDNWMjJIMTguMTRDMTUuODYgMjIgMTQgMjAuMjc2IDE0IDE4LjE1OFYxNWgzLjg4NXoiIGlkPSJhIi8+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNCAtMTUpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9Ii42Ij48bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+PHVzZSB4bGluazpocmVmPSIjYSIvPjwvbWFzaz48dXNlIGZpbGw9IiNGNTYzMDAiIHhsaW5rOmhyZWY9IiNhIi8+PGcgbWFzaz0idXJsKCNiKSIgZmlsbD0iI0Y1NjMwMCI+PHBhdGggZD0iTTAgMGgzNnYzNkgweiIvPjwvZz48L2c+PC9zdmc+);
            }
        }
    }
}

.Cta--outline {
    background: none;
    color: $text-color;
    border: 1px solid $primary-color-light;
    text-align: left;
    margin-top: u($spacing-unit--xs);
    padding: u($spacing-unit * 0.75);

    p {
        margin: 0;
    }

    .Icon {
        width: u($spacing-unit);
        height: u($spacing-unit);
        color: $primary-color;
    }
}

.Cta--customWork {

    .Icon {
        margin-right: -1rem;
    }
}
