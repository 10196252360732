.Excerpt {
    font-family: $font-highlight;
    font-size: u(16);
    line-height: u(22);
    color: $tertiary-color;

    @include mq($from: $viewport--md) {
        font-size: u(18);
        line-height: u(26);
    }
}
