.Tags {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: u(14);

    &-item {
        margin-right: u($spacing-unit--xs);
        margin-bottom: u($spacing-unit);
    }

    &-link {
        color: $primary-color;
        border: 1px solid $primary-color-light;
        border-radius: rem(10) 0;
        padding: u(4) u($spacing-unit--sm) u($spacing-unit--xs);
        transition: border-color $global-transition;

        &:hover {
            border-color: $tertiary-color;
            text-decoration: none;
            color: $primary-color;
        }

        &.is-active {
            color: $white;
            background: $primary-color;
            border-color: $primary-color;
            cursor: default;
        }
    }
}
