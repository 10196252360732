/* Fields: field */
%Form-field--reset {
    @include font-smoothing($font-smoothing);
    appearance: none;
    background-color: $form-element-background-color;
    border-radius: if($form-element-border-radius > 0, px($form-element-border-radius), 0);
    border: px($form-element-border-width) solid $form-element-border-color;
    color: $form-element-color;
    vertical-align: middle;
    width: 100%;
    line-height: u(20);

    // Error
    .#{$form-namespace}Form-item.is-error & {
        border-color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        border-color: $form-success-color;
    }
}

.#{$form-namespace}Form-field {
    font-family: inherit;
    position: relative;
}

// Indicator for file, radio & checkbox elements
.#{$form-namespace}Form-indicator {
    user-select: none;
}

// Sizes
.#{$form-namespace}Form-field--xs { max-width: u($form-element--xs) !important; }
.#{$form-namespace}Form-field--sm { max-width: u($form-element--sm) !important; }
.#{$form-namespace}Form-field--md { max-width: u($form-element--md) !important; }
.#{$form-namespace}Form-field--lg { max-width: u($form-element--lg) !important; }
.#{$form-namespace}Form-field--xl { max-width: u($form-element--xl) !important; }
.#{$form-namespace}Form-field--full { max-width: none !important; }
