.Details {
    border-bottom: 1px solid $cc-grey;
    padding-bottom: u($spacing-unit);
    margin-bottom: u($spacing-unit--xl);

    h3 {
        color: $tertiary-color;
        margin-bottom: u($spacing-unit--xs);
    }

    h4 {
        color: $text-color;
    }

    .Icon-chevron {
        width: u(10);
        margin: u($spacing-unit--sm);

        path {
            stroke-width: 2;
        }
    }

    &.is-active {
        .Details-content {
            max-height: u(2000);
            transition: max-height $global-transition;

            @include mq($from: $viewport--md) {
                max-height: u(1000);
            }

            @include mq($from: $viewport--lg) {
                max-height: u(750);
            }
        }

        .Details-less {
            display: flex;
        }

        .Details-more {
            display: none;
        }
    }
}

.Details-more,
.Details-less {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.Details-more {
    .Icon-chevron {
        transform: rotate(90deg);
    }
}

.Details-less {
    display: none;

    .Icon-chevron {
        transform: rotate(-90deg);
    }
}

.Details-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height $global-transition;

    h1, h2, h3, h4 {
        color: $primary-color;
    }
}
