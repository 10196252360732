.ListImage {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin: u($spacing-unit--lg) 0;
}

.ListImage-item {
    margin-right: u($spacing-unit);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ListImage-object {
    width: u(40);
    margin-right: u($spacing-unit--xs);
}
