.Extender {
    .Category-preview {
        margin-bottom: u($spacing-unit);
    }

    &.is-extended {
        .Extender-button {
            display: none;
        }

        .Extender-extra {
            display: flex;
        }
    }
}

.Extender-extra {
    display: none;
}

.Extender-button {
    color: $primary-color;
    margin: 0 auto u($spacing-unit) auto;
    width: u($spacing-unit);
    height: u($spacing-unit);
    text-align: center;
    display: block;
}


