/* Colors: Theme */

// Lay-out
$primary-color:                     #10A74B !default;
$primary-color-light:               #D9E0CE !default;
$primary-color-lighter:             #F1F7E9 !default;
$primary-color-dark:                #058E77;
$primary-color-darker:              #0C5151;

$secondary-color:                   #FFAC0A !default;
$secondary-color-light:             #FFFBD5;
$secondary-color-dark:              #754C29;

$tertiary-color:                    #0C6667 !default;

$text-color:                        #333646 !default;
$bg-color:                          $white !default;
$border-color:                      $cc-grey !default;
$image-color:                       $grey--200 !default;

$header-bg-color:                   $primary-color !default;
$footer-bg-color:                   $grey--200 !default;

// Modifiers
$theme-modifiers: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "tertiary": $tertiary-color,
    "success": $green,
    "info": $blue,
    "warning": $orange,
    "danger": $red,
    "white": $white
) !default;
