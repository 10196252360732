.mobile-lang-nav {
    position: relative;
    z-index: 2;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include mq($until: $viewport--md - 1) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .Nav-item {
        margin-right: 2rem;
        margin-left: 0 !important;
    }
}

.Nav--lang {
    font-size: 1.4rem;
    text-transform: uppercase;

    .Nav-toggle {
        display: none;
    }

    .Nav-item {
        margin-left: 2rem;
    }

    .Nav-link {
        color: rgba(white, 0.6);

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            color: white;
        }
    }
}
