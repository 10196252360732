.Nav--footer {

    .Nav-list {
        display: block;
    }

    .Nav-link {
        color: $white;
        position: relative;
        padding-bottom: u(6);

        &:hover,
        &:focus,
        &.is-active {
            .Icon-chevron {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }

    .Icon-chevron {
        position: absolute;
        width: u(5);
        left: u(-$spacing-unit--sm);
        top: 0;
        bottom: 0;
        margin: auto;
        transition: transform $global-transition, opacity $global-transition;
        opacity: 0;
        transform: translate(u(-$spacing-unit--sm), 0);
    }
}
