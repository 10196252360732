/* Fields: check */
.#{$form-namespace}Form-field--radio,
.#{$form-namespace}Form-field--checkbox {
    cursor: pointer;
}

.Form-item--list {
    .Form-label {
        display: none;
    }

    .Form-controls {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.Form-field--checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: u($spacing-unit--sm);
}

.Form-field--radio {
    margin-right: u($spacing-unit--sm);
    color: $tertiary-color;
}

input[type="checkbox"],
input[type="radio"] {
    margin-right: u($spacing-unit--xs);
}
