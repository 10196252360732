.Accordeon-items {
    border-bottom: 1px solid $cc-grey;
    padding: 0;
    list-style: none;
}

.Accordeon-item {
    border-top: 1px solid $cc-grey;
    padding: u($spacing-unit / 2) u($spacing-unit--xs);

    &:hover {
        .Accordeon-arrow {
            transform: rotate(90deg);
        }
    }

    &.is-active {
        .Accordeon-arrow {
            transform: rotate(90deg);
        }

        .Accordeon-content {
            max-height: 1000px;
            transition: max-height $global-transition;

            @include mq($from: $viewport--md) {
                max-height: 500px;
            }

            @include mq($from: $viewport--lg) {
                max-height: 300px;
            }
        }
    }
}

.Accordeon-question {
    display: flex;
    font-weight: 600;
    cursor: pointer;
    color: $black;
}

.Accordeon-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height $global-transition;
    padding-left: u($spacing-unit--lg - 2);
}

.Accordeon-content-inner {
    padding-top: u($spacing-unit--sm);

    ul {
        list-style-type: disc;
    }

    h2 {
        font-size: u($heading-size-5);
        color: #10A74B;
    }
}

.Accordeon-arrow {
    color: $primary-color;
    padding: 0;
    margin-right: u($spacing-unit);
    transition: transform $global-transition;

    .Icon-chevron {
        width: u(10);

        path {
            stroke-width: 2px;
        }
    }
}
