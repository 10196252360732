.Quote {
    position: relative;
    padding: u(24) u(40) u(30) u(40);
    background-color: #F1F7E9;
    border-radius: u(30) 0 u(30) 0;
    font-family: $font-highlight;
    font-size: u(16);
    color: $primary-color-darker;
    max-width: u(550);

    p {
        margin: 0;
    }

    &::after {
        position: absolute;
        left: 0;
        bottom: u(-20);
        width: u(14);
        height: u(20);
        content: '';
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMiAyMGEyIDIgMCAwIDAgMi0ydi04QzQgNC40NzcgOC40NzcgMCAxNCAwaDYzNGMxNi41NjkgMCAzMC0xMy40MzEgMzAtMzB2LTY0SDMwQzEzLjQzMS05NCAwLTgwLjU2OSAwLTY0djgyYTIgMiAwIDAgMCAyIDJ6IiBmaWxsPSIjRjFGN0U5Ii8+PC9nPjwvc3ZnPg==);
    }

    &-icon {
        position: absolute;
        top: u(-12);
        right: u(28);
        z-index: 10;
        width: u(24);
        height: u(24);

        path {
            fill: $primary-color;
        }
    }
}