/* Footer */
.Footer {
    // Top
    &-top {
        background: linear-gradient(-179deg, $primary-color-dark 0%, $tertiary-color 100%);
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit * 3);
        color: $white;
    }

    &-top-inner {
        position: relative;

        .Link {
            color: $white;
            text-decoration: none;
        }
    }

    // Bottom
    &-bottom {
        background-color: $primary-color-darker;
        color: $white;
        font-size: u(14);
        padding: u($spacing-unit) 0 u($spacing-unit--xl) 0;

        &--light {
            border-top: 1px solid $cc-grey;
            background: $white;
            color: $grey-darker;

            .Nav-link,
            .DigitalPulse {
                color: $grey-darker;
            }
        }
    }

    &-bottom-inner {
        @include mq($from: $viewport--md) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &-bottom-nav {
        display: flex;
        align-items: flex-start;
        margin: u($spacing-unit) 0;

        @include mq($from: $viewport--md) {
            margin: 0;
        }

        .Icon {
            width: u($spacing-unit);
        }
    }

    .Logo-stamp {
        width: u(94);
        position: absolute;
        top: u(-$spacing-unit);
        right: 0;
        transform: translateY(u(-15)) rotate(20deg);
    }

    .Icon-leaves {
        color: $primary-color-darker;
        width: u(116);
        position: absolute;
        bottom: u(-$spacing-unit * 3);
        right: 0;
        opacity: 0.5;
    }

    h3 {
        color: $white;
    }
}
