/* Header */
.Header {
    position: fixed;
    width: 100%;
}

.Header-main {
    background-color: $header-bg-color;

    @include mq($until: $viewport--md) {
        padding-top: u($spacing-unit * 0.75);
        padding-bottom: u($spacing-unit * 0.75);
        position: relative;
        z-index: 100;
    }

    > .Container {
        display: flex;
        align-items: center;
    }

    .Navigation {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include mq($from: $viewport--md) {
            justify-content: space-between;
        }
    }

    .Navigation-side {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .Nav--lang {
        color: $white;
    }
}

.Header-sub {
    background-color: $primary-color-lighter;
    color: $tertiary-color;
}

.Header-search {
    background-color: $white;
    padding: u($spacing-unit * 0.75) 0;
}

.HeaderTop {
    border-bottom: 1px solid white;
    color: white;
    background-color: $primary-color;

    > .Container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.Nav--top {
    position: relative;
    z-index: 2;
    font-size: 1.4rem;

    .Nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: rgba(white, 0.8);

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            color: white;
        }
    }

    @include mq($until: 767px) {
        padding-top: 2rem;

        .Nav-item {
            margin-right: 2rem;
        }
    }

    @include mq($from: 768px) {

        .Nav-item:not(:first-child) {
            border-left: 1px solid white;

            .Nav-link {
                padding-left: 2rem;
            }
        }

        .Nav-item:not(:last-child) {

            .Nav-link {
                padding-right: 2rem;
            }
        }
    }
}
