/* Settings: Fonts */
@font-face {
    font-family: 'Source Sans Pro';
    src:    url('../fonts/SourceSansPro-Regular.woff') format('woff'),
            url('../fonts/SourceSansPro-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src:    url('../fonts/SourceSansPro-Bold.woff') format('woff'),
            url('../fonts/SourceSansPro-Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Suomi Slab Serif';
    src:    url('../fonts/SuomiSlabSerif-Regular.woff') format('woff'),
            url('../fonts/SuomiSlabSerif-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

// Default
$font-sans:                             -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
$font-mono:                             SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

// Custom
$font-heading:                          "filson-pro", $font-sans !default; // 400, 700
$font-body:                             "Source Sans Pro", $font-sans !default;
$font-highlight:                        "Suomi Slab Serif", $font-sans !default;
