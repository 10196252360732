.Text {

    &--withTips {
        display: flex;
        color: $primary-color;
        font-weight: 600;

        .Icon-check {
            margin-right: u($spacing-unit);

            path:last-of-type {
                fill: $primary-color;
            }
        }
    }
}