.Social-list {
    list-style: none;
    padding: 0;
    display: flex;
}

.Social-item {
    margin-right: u($spacing-unit--sm);
}

.Social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: u(26);
    height: u(26);
    border-radius: 50%;
    background: $white;
    color: $primary-color-dark;

    .Icon {
        height: u(18);
        width: u(18);
    }
}

/**
 * Modifiers
 */
.Social--large {
    .Social-link {
        background: $primary-color;
        color: $white;
    }
}
