/* Colors: Custom */

// Grey
$cc-grey:                           #D9E0CE !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);

$grey-darker:                       #868e96;
$grey-dark:                         #495057;
$grey-light:                        #F2F2EE;

$orange:                        #FBB040;
$orange-dark:                        #F56300;
