.Text-image {

    &-figure-image {
        width: 100%;
    }

    &-wrapper {
        position: relative;
    }

    &-label {
        background-color: $primary-color-darker;
        color: $white;
        position: absolute;
        left: u($spacing-unit--sm);
        bottom: u($spacing-unit--sm);
        padding: u($spacing-unit--xs) u($spacing-unit--sm);
        text-transform: uppercase;
        font-style: italic;
        font-size: u(14);
        font-family: $font-body;
        font-weight: 400;
    }
}