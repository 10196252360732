@include mq($from: $viewport--md) {

    .Nav--header-main {
        padding: 0;

        // @include mq($until: $viewport--md) {
        //    position: relative;
        //    height: 60%;
        //    overflow: scroll;
        // }

        @include mq($from: $viewport--md) {
            margin-left: u(-$spacing-unit);
            margin-right: u(-$spacing-unit);
        }

        .Nav-list {
            align-items: center;

            // @include mq($until: $viewport--md) {
            //     display: block;
            // }
        }

        .Nav-item {
            &.is-open {
                .Nav-list-sub {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }


        .Nav-link {
            padding: u($spacing-unit--sm + ($spacing-unit * 0.75)) u($spacing-unit--sm);
            color: $white;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: 600;

            &.is-active,
            &:hover,
            &:focus {
                color: rgba($white, 0.8);
            }

            @include mq($from: $viewport--md) {

                &.is-highlight {
                    padding-top: 0.5rem;
                    padding-right: u($spacing-unit--sm);
                    padding-bottom: 0.65rem;
                    padding-left: u($spacing-unit--sm);
                    background-color: $orange;
                    border-radius: 5px;

                    &:hover,
                    &:focus,
                    &:active {
                        color: white;
                        background-color: rgba($orange, 0.9);
                    }
                }
            }

            // @include mq($until: $viewport--md) {
            //     justify-content: space-between;
            //     font-size: u(20);
            //     padding: u($spacing-unit * 0.75) 0;
            //     border-bottom: 1px solid rgba($white, 0.2);
            // }
        }

        .Icon-chevron {
            width: u(7);
            margin: u($spacing-unit--sm);
        }

        .Icon-chevron--turn {

            @include mq($from: $viewport--md) {
                transform: rotate(90deg);
            }
        }

        .Nav-list-sub {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            list-style: none;
            padding: u($spacing-unit * 0.75) 0;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity $global-transition;
            z-index: 1;
            min-height: u(485);

            &.is-open {
                opacity: 1;
                visibility: visible;
                pointer-events: initial;

                &:after {
                    width: 100%;
                }

                &:before {
                    opacity: 1;
                }
            }

            // @include mq($until: $viewport--md) {
            //     color: $white;
            //     font-size: u(20);
            //     font-weight: 600;
            //     background: $primary-color;
            //     height: 100%;
            //     overflow-y: scroll;
            // }

            @include mq($from: $viewport--md) {
                top: calc(100% - 4px);
                left: u(40);
                width: calc(100% - 8rem);
                padding: u($spacing-unit) 0;
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: u($spacing-unit);
                height: calc(100% - 48px);
                width: u(320);
                z-index: -1;
                border-right: 1px solid $primary-color-light;
                transition: opacity $global-transition;
                opacity: 0;
                pointer-events: none;

                // @include mq($until: $viewport--md) {
                //     display: none;
                // }
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: u(320);
                box-shadow: 0 u(10) u(15) 0 rgba($black, 0.1);
                background: $white;
                z-index: -2;
                transition: width $global-transition;

                // @include mq($until: $viewport--md) {
                //     display: none;
                // }
            }

            .Nav-link {
                padding: u($spacing-unit--xs) u($spacing-unit + $spacing-unit--sm);
                color: $tertiary-color;
                width: u(320);
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 400;

                // @include mq($until: $viewport--md) {
                //     font-size: u(20);
                //     padding: u($spacing-unit * 0.75) 0;
                //     border-bottom: 1px solid rgba($white, 0.2);
                //     color: $white;
                //     font-weight: 400;
                //     width: 100%;
                // }

                &.is-active,
                &:hover,
                &:focus {
                    background: rgba($primary-color, 0.15);

                    .Icon-arrow {
                        opacity: 1;
                    }
                }
            }
        }

        .Icon-arrow {
            width: u(15);
            color: $primary-color;
            opacity: 0;
            transition: opacity $global-transition;
        }

        .Nav-child {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100%);
            padding: u($spacing-unit * 0.75) 0;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity $global-transition;

            &.is-open {
                opacity: 1;
                visibility: visible;
                pointer-events: initial;
            }

            // @include mq($until: $viewport--md) {
            //     color: $white;
            //     font-size: u(20);
            //     font-weight: 600;
            //     background: $primary-color;
            //     height: 100%;
            //     overflow-y: scroll;
            // }

            @include mq($from: $viewport--md) {
                left: u(320);
                width: calc(100% - 320px);
                padding: u($spacing-unit + 22) u($spacing-unit + $spacing-unit--sm);
            }

            @include mq($from: 960px) {
                padding: u($spacing-unit + 22) u($spacing-unit * 4);
            }

            .Nav-child-title {
                font-weight: 600;
                color: $primary-color;
                font-size: u(22);
                letter-spacing: 1px;

                // @include mq($until: $viewport--md) {
                //     margin-top: 0;
                //     color: $white;
                //     font-size: u(20);
                // }
            }

            @include mq($from: $viewport--md) {
                .Nav-item {
                    &:hover,
                    &:focus {
                        .Nav-child-object {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }

        .Nav-child-list {
            list-style: none;
            padding: 0;

            .Nav-link {
                padding-left: 0;
                font-weight: 400;

                // @include mq($until: $viewport--md) {
                //     font-size: u(20);
                //     padding: u($spacing-unit * 0.75) 0;
                //     border-bottom: 1px solid rgba($white, 0.2);
                //     color: $white;
                //     font-weight: 400;
                //     width: 100%;
                // }

                &.is-active,
                &:hover,
                &:focus {
                    background: none;
                    color: $primary-color;
                }
            }
        }

        .Nav-child-object {
            position: absolute;
            top: u($spacing-unit);
            right: u($spacing-unit + $spacing-unit--sm);
            width: u(150);
            opacity: 0;
            visibility: hidden;

            // @include mq($until: $viewport--md) {
            //     display: none;
            // }

            @include mq($from: 960px) {
                width: u(300);
            }
        }
    }
}

@include mq($until: $viewport--md - 1) {

    .Navigation-body-footer {
        position: relative;

        &::after {
            content: '';
            @include position(absolute, -30px, 0, 0, 0);
            @include dimensions(100%, 30px);
            background-color: rgba($black, .3);
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) -200%, rgba(255,255,255,0) 40%);
        }
    }

    .Nav--header-main {
        position: relative;
        height: 80%;
        background: $primary-color;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        margin-bottom: u(30);

        &::-webkit-scrollbar { width: 8px; }
        &::-webkit-scrollbar-track { background: transparent; }
        &::-webkit-scrollbar-thumb:hover { background: #145622; }
        &::-webkit-scrollbar-thumb { background: #207e34; border-radius: 10px; }

        .Icon-chevron {
            width: u(7);
            margin-left: u($spacing-unit--sm);
        }

        .Icon-arrow {
            width: u(15);
            color: $primary-color;
            opacity: 0;
            transition: opacity $global-transition;
        }

        .Nav-child-object {
            display: none;
        }

        .Nav-list {
            flex-direction: column;
            list-style: none;
            margin: 0;
            background: $primary-color;

            > .Nav-item {
                width: 100%;
            }
        }

        .Nav-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
            font-size: u(20);
            padding: u($spacing-unit * 0.75) 0;
            border-bottom: 1px solid rgba($white, 0.2);
            font-weight: 600;

            &.is-active,
            &:hover,
            &:focus {
                color: rgba($white, 0.8);
            }
        }

        .Nav-child {

            &.is-open {
                overflow-y: auto;
            }
        }

        .Nav-list-sub,
        .Nav-child {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $primary-color;

            &.is-open {
                display: flex;
                flex-direction: column;

                .Nav-list-child {
                    flex-grow: 1;
                }
            }
        }

        .Nav-list-sub,
        .Nav-list-child,
        .Nav-child-list {
            list-style: none;
            margin: 0;
            padding: 0;
            color: $white;
            font-size: u(20);
            font-weight: 600;
        }

        .Nav-child {

            // &::before {
            //     content: '';
            //     position: fixed;
            //     bottom: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 80%;
            //     background: $primary-color;
            // }

            > .Nav-child-list > .Nav-item > .Nav-link {
                position: relative;
                z-index: 1;
                background: $primary-color;
            }
        }

        .Nav-list,
        .Nav-list-sub,
        .Nav-child-list {
            height: 100%;
            flex-wrap: nowrap;
            padding: u(0, 20);

            &.disable-scroll {
                overflow-y: hidden;
            }
        }

        .Nav-child-title {
            @include font-size(30);
            padding: u(0, 20);
            margin-bottom: u($spacing-unit--sm);
        }

        .Nav-list-sub-title {
            @include font-size(30);
            margin-bottom: 0;
            padding-bottom: u($spacing-unit--sm);
        }
    }

    .Nav-child-title {
        position: relative;
        z-index: 2;
        margin-top: 0;
        font-weight: 600;
        color: $white;
        font-size: u(20);
    }
}

.Navigation-body-header {

    @include mq($until: 768px) {
        z-index: 2;
    }
}

.Navigation-back {

    @include mq($until: 768px) {
        z-index: 2;
    }
}
