.Figure-wrapper {
    position: relative;

    @include mq($from: $viewport--md) {
        height: 70vh;

        .Figure-object {
            height: 70vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .Figure-image {
            object-fit: cover;
            height: 100%;
        }
    }

    &-leaves {
        position: absolute;
        z-index: -1;
        bottom: u(30px);
        left: u(-150px);
        width: u(160px);
        height: u(160px);

        path {
            fill: $primary-color-lighter;
        }
    }
}

.Figure-caption {
    position: absolute;
    top: auto;
    right: u($spacing-unit * 2);
    bottom: u(-$spacing-unit--lg);
    border-radius: 0 u(30) 0 u(30);
    background-image: linear-gradient(-179deg, $primary-color-dark 0%, $tertiary-color 99%);
    color: $white;
    padding: u($spacing-unit);
    max-width: u(450);

    @include mq($until: $viewport--sm) {
        position: relative;
        bottom: auto;
        right: auto;
        max-width: none;
        // left: 0;
        // right: auto;
        // margin: 0 u($spacing-unit--sm);
        // width: calc(100% - #{ u($spacing-unit--sm * 2)} );
    }

    @include mq($from: $viewport--lg) {
        line-height: u($spacing-unit--lg)
    }

    &:before {
        content: "";
        position: absolute;
        top: u(-19);
        left: 0;
        width: u(15);
        height: u(20);
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IHgxPSI1MS4wNzYlIiB5MT0iMCUiIHgyPSI1MS4wNzYlIiB5Mj0iOTguOTI0JSIgaWQ9ImEiPjxzdG9wIHN0b3AtY29sb3I9IiMwNThFNzciIG9mZnNldD0iMCUiLz48c3RvcCBzdG9wLWNvbG9yPSIjMEM2NjY3IiBvZmZzZXQ9IjEwMCUiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cGF0aCBkPSJNMiAwYTIgMiAwIDAgMSAyIDJ2OGMwIDUuNTIzIDQuNDc3IDEwIDEwIDEwaDI5MmMxNi41NjkgMCAzMCAxMy40MzEgMzAgMzB2ODZIMzBjLTE2LjU2OSAwLTMwLTEzLjQzMS0zMC0zMFYyYTIgMiAwIDAgMSAyLTJ6IiBmaWxsPSJ1cmwoI2EpIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
    }
}

.Figure-wrapper--dealers {

    .Figure-caption {

        @include mq($until: 639px) {
            position: relative;
            margin-top: -6rem;
        }
    }
}

.Figure-wrapper.has-link {
    display: block;
}
