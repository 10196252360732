/* Logo */
.Logo {
    flex-shrink: 0;
    margin-right: auto;

    svg {
        display: block;
        width: u(115px);
    }
}

.Logo--header {
    margin-right: u($spacing-unit--lg + $spacing-unit--sm);
}
