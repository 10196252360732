.Switch {
    text-align: right;
    margin-left: auto;
    margin-right: 0;
}

.Switch-button {
    border: 1px solid $cc-grey;
    padding: u($spacing-unit--sm) u($spacing-unit);
    color: rgba(51, 54, 70, 0.5);
    background: $grey-light;

    &:first-child {
        border-radius: u(3) 0 0 u(3);
    }

    &:last-child {
        border-left: none;
        border-radius: 0 u(3) u(3) 0;
    }

    &.is-active {
        color: $text-color;
        background: $white;
    }
}
