.Nav--breadcrumbs {
    background: $primary-color-lighter;
    border-top: 2px solid $white;
    font-size: u(14);
    color: $primary-color;

    .Nav-item {
        padding: u(7) 0 u(9);
        display: flex;
        justify-content: flex-start;
        align-items: baseline;

        &.is-active {
            .Nav-link {
                color: $tertiary-color;
            }
        }
    }

    .Nav-chevron {
        width: u(6);
        height: u(9);
        margin: 0 u($spacing-unit--sm);
    }
}
