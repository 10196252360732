/* Link */
a {
    @include transition(#{background-color, color});
    color: $link-color;
    text-decoration: $link-text-decoration;

    &:hover,
    &:focus,
    &:active {
        color: $link-color--hover;
        text-decoration: $link-text-decoration--hover;
    }
}

.Link-none {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: inherit;
    }
}

.Link-primary {
    color: $primary-color;

    &:hover {
        color: $tertiary-color;
        text-decoration: underline;
    }
}
