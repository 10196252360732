/* Blocks */
.Block-spaced {
    margin: u($spacing-unit) 0;
}

@import '7-theme/blocks/accordeon';
@import '7-theme/blocks/text-image';
@import '7-theme/blocks/media';
@import '7-theme/blocks/list-image';
@import '7-theme/blocks/text';
@import '7-theme/blocks/brochure';
